import * as Yup from "yup";

export const enviroment = process.env.REACT_APP_MODE;

export const version = process.env.REACT_APP_VERSION;

let warning = false;

export const baseurl = process.env.REACT_APP_API;

if (enviroment === "dev") {
  console.log("baseurl", baseurl);
  console.log("warning", warning);
  warning = true;
}

export const warningHeader = warning;

export const waitTime = 3000;

export const shortbrand = "A Tiempo Planea";

export const brand = "A Tiempo Planea";

export const leyend = "Planeando tu futuro";

export const longbrand = "Planeando tu futuro";

export const arrEstudios = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];
export const arrEstudiosName = "Estudios";

// Sections should be added here

// export const arrSga = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
// export const arrSgaName = "SGA";

export const arrMenu = [
  [arrEstudios, arrEstudiosName],
  // [arrSga, arrSgaName],
];

export const urlBaseEstudios = "/estudios";
export const urlBaseDashboard = `${urlBaseEstudios}/dashboard`;
export const urlBaseCaptura = `${urlBaseEstudios}/captura`;

export const urlBaseAdministracion = "/administracion";
export const urlBaseAdministracionDashboard = `${urlBaseAdministracion}/dashboard`;

export const tiposPersonas = [
  { text: "Persona Física" },
  { text: "Persona Física con Actividad Empresarial" },
  { text: "Persona Moral" },
];

export const regimenesFiscales = [
  { text: "601 - General de Ley Personas Morales" },
  { text: "603 - Personas Morales con Fines no Lucrativos" },
  { text: "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios" },
  { text: "606 - Arrendamiento" },
  { text: "607 - Régimen de Enajenación o Adquisición de Bienes" },
  { text: "608 - Demás ingresos" },
  { text: "609 - Consolidación" },
  { text: "611 - Ingresos por Dividendos (socios y accionistas)" },
  {
    text: "612 - Personas Físicas con Actividades Empresariales y Profesionales",
  },
  { text: "614 - Ingresos por intereses" },
  { text: "615 - Régimen de los ingresos por obtención de premios" },
  { text: "616 - Sin obligaciones fiscales" },
  {
    text: "620 - Sociedades Cooperativas de Producción que optan por Diferir sus Ingresos",
  },
  { text: "621 - Incorporación Fiscal" },
  { text: "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras" },
  { text: "623 - Opcional para Grupos de Sociedades" },
  { text: "624 - Coordinados" },
  { text: "626 - RESICO" },
  { text: "628 - Hidrocarburos" },
  {
    text: "629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
  },
  { text: "630 - Enajenación de acciones en bolsa de valores" },
];

export const usosCfdi = [
  { text: "G01 - Adquisición de mercancías" },
  { text: "G02 - Devoluciones, descuentos o bonificaciones" },
  { text: "G03 - Gastos en general" },
  { text: "I01 - Construcciones" },
  { text: "I02 - Mobilario y equipo de oficina por inversiones" },
  { text: "I03 - Equipo de transporte" },
  { text: "I04 - Equipo de computo y accesorios" },
  { text: "I05 - Dados, troqueles, moldes, matrices y herramental" },
  { text: "I06 - Comunicaciones telefónicas" },
  { text: "I07 - Comunicaciones satelitales" },
  { text: "I08 - Otra maquinaria y equipo" },
  { text: "D01 - Honorarios médicos, dentales y gastos hospitalarios" },
  { text: "D02 - Gastos médicos por incapacidad o discapacidad" },
  { text: "D03 - Gastos funerales" },
  { text: "D04 - Donativos" },
  {
    text: "D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
  },
  { text: "D06 - Aportaciones voluntarias al SAR" },
  { text: "D07 - Primas por seguros de gastos médicos" },
  { text: "D08 - Gastos de transportación escolar obligatoria" },
  {
    text: "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
  },
  { text: "D10 - Pagos por servicios educativos (colegiaturas)" },
  { text: "P01 - Por definir" },
];

export const promotorias = [
  { key: "Freti", value: "Freti", text: "Freti" },
  {
    key: "Estratega Lobo & Asociados",
    value: "Estratega Lobo & Asociados",
    text: "Estratega Lobo & Asociados",
  },
  { key: "Profundum", value: "Profundum", text: "Profundum" },
  { key: "LOMA", value: "LOMA", text: "LOMA" },
  { key: "Otra", value: "Otra", text: "Otra" },
  { key: "Ninguna", value: "Ninguna", text: "Ninguna" },
];

export const tipos_estudio = [
  { key: "Diagnóstico", value: "Diagnóstico", text: "Diagnóstico" },
  {
    key: "Proyectivo de Pensión",
    value: "Proyectivo de Pensión",
    text: "Proyectivo de Pensión",
  },
];
export const estadosRepublicaMexicana = [
  { key: "Aguascalientes", text: "Aguascalientes", value: "Aguascalientes" },
  { key: "Baja California", text: "Baja California", value: "Baja California" },
  {
    key: "Baja California Sur",
    text: "Baja California Sur",
    value: "Baja California Sur",
  },
  { key: "Campeche", text: "Campeche", value: "Campeche" },
  { key: "Chiapas", text: "Chiapas", value: "Chiapas" },
  { key: "Chihuahua", text: "Chihuahua", value: "Chihuahua" },
  {
    key: "Ciudad de México",
    text: "Ciudad de México",
    value: "Ciudad de México",
  },
  { key: "Coahuila", text: "Coahuila", value: "Coahuila" },
  { key: "Colima", text: "Colima", value: "Colima" },
  { key: "Durango", text: "Durango", value: "Durango" },
  {
    key: "Estado de México",
    text: "Estado de México",
    value: "Estado de México",
  },
  { key: "Guanajuato", text: "Guanajuato", value: "Guanajuato" },
  { key: "Guerrero", text: "Guerrero", value: "Guerrero" },
  { key: "Hidalgo", text: "Hidalgo", value: "Hidalgo" },
  { key: "Jalisco", text: "Jalisco", value: "Jalisco" },
  { key: "Michoacán", text: "Michoacán", value: "Michoacán" },
  { key: "Morelos", text: "Morelos", value: "Morelos" },
  { key: "Nayarit", text: "Nayarit", value: "Nayarit" },
  { key: "Nuevo León", text: "Nuevo León", value: "Nuevo León" },
  { key: "Oaxaca", text: "Oaxaca", value: "Oaxaca" },
  { key: "Puebla", text: "Puebla", value: "Puebla" },
  { key: "Querétaro", text: "Querétaro", value: "Querétaro" },
  { key: "Quintana Roo", text: "Quintana Roo", value: "Quintana Roo" },
  { key: "San Luis Potosí", text: "San Luis Potosí", value: "San Luis Potosí" },
  { key: "Sinaloa", text: "Sinaloa", value: "Sinaloa" },
  { key: "Sonora", text: "Sonora", value: "Sonora" },
  { key: "Tabasco", text: "Tabasco", value: "Tabasco" },
  { key: "Tamaulipas", text: "Tamaulipas", value: "Tamaulipas" },
  { key: "Tlaxcala", text: "Tlaxcala", value: "Tlaxcala" },
  { key: "Veracruz", text: "Veracruz", value: "Veracruz" },
  { key: "Yucatán", text: "Yucatán", value: "Yucatán" },
  { key: "Zacatecas", text: "Zacatecas", value: "Zacatecas" },
];

export const tiposImagenesCaptura = [
  {
    text: "RFC",
    value: "rfc_imagen",
  },
  {
    text: "CURP",
    value: "curp_imagen",
  },
  {
    text: "Comprobante de pago",
    value: "comprobantepago_imagen",
  },
  {
    text: "Estado de cuenta del Afore",
    value: "afore_imagen",
  },
  {
    text: "INE anverso",
    value: "ineanverso_imagen",
  },
  {
    text: "INE reverso",
    value: "inereverso_imagen",
  },
  {
    text: "Otro documento 1",
    value: "otra1_imagen",
  },
  {
    text: "Otro documento 2",
    value: "otra2_imagen",
  },
  {
    text: "Otro documento 3",
    value: "otra3_imagen",
  },
  {
    text: "Otro documento 4",
    value: "otra4_imagen",
  },
  {
    text: "Otro documento 5",
    value: "otra5_imagen",
  },
];

export const tiposImagenesDisplay = [
  { key: "afore_imagen", text: "Afore" },
  { key: "comprobantepago_imagen", text: "Comprobante de pago" },
  { key: "curp_imagen", text: "CURP" },
  { key: "ineanverso_imagen", text: "INE anverso" },
  { key: "inereverso_imagen", text: "INE reverso" },
  { key: "rfc_imagen", text: "RFC" },
  {
    key: "constancia_situacion_fiscal",
    text: "Constancia de situación fiscal",
  },
  { key: "logo", text: "Logotipo propio" },
  { key: "otra1_imagen", text: "Otro documento 1" },
  { key: "otra2_imagen", text: "Otro documento 2" },
  { key: "otra3_imagen", text: "Otro documento 3" },
  { key: "otra4_imagen", text: "Otro documento 4" },
  { key: "otra5_imagen", text: "Otro documento 5" },
];

export const tiposLogos = [
  { text: "Logo de ATiempo", value: "logoATiempo" },
  { text: "Logo Propio", value: "logoPropio" },
  { text: "Sin Logo", value: "sinLogo" },
];

export const textosCambioNivel = [
  { id: 0, text: "Enviar a Captura", toast: "Estudio enviado a Captura" },
  { id: 1, text: "Enviar a Validación", toast: "Estudio enviado a Validación" },
  { id: 2, text: "Enviar a En Proceso", toast: "Estudio enviado a En Proceso" },
  { id: 3, text: "Enviar a Completado", toast: "Estudio enviado a Completado" },
  { id: 4, text: "Enviar a Reprocesos", toast: "Estudio enviado a Reprocesos" },
  { id: 5, text: "Regresar a Captura", toast: "Estudio regresado a Captura" },
  {
    id: 6,
    text: "Regresar a Validación",
    toast: "Estudio regresado a Validación",
  },
  {
    id: 7,
    text: "Regresar a En Proceso",
    toast: "Estudio regresado a En Proceso",
  },
  {
    id: 8,
    text: "Regresar a Completado",
    toast: "Estudio regresado a Completado",
  },
  {
    id: 9,
    text: "Regresar a Reprocesos",
    toast: "Estudio regresado a Reprocesos",
  },
];

export const textosPPs = [
  { text: "Válida", value: "valid" },
  { text: "No válida", value: "invalid" },
  { text: "No se proporcionó", value: "notProvided" },
  { text: "Si", value: "s" },
  { text: "No", value: "n" },
  { text: "No disponible en SINDO", value: "na" },
  { text: "No proporcionado", value: "np" },
  { text: "No corresponde", value: "no_corresponde" },
  {
    text: "Corresponde sin homoclave",
    value: "corresponde_sin_homoclave",
  },
  {
    text: "No proporcionó RFC",
    value: "nprfc",
  },
  {
    text: "Corresponde con homoclave",
    value: "corresponde_con_homoclave",
  },
  {
    text: "No proporcionó estado de cuenta de su AFORE",
    value: "np",
  },
];

export const camposPPs = [
  {
    text: "Confirmación de respuesta del INE",
    value: "confirmacion_respuesta_ine",
    yup: Yup.mixed().oneOf(["valid", "invalid", "notProvided"]).required(),
    type: "dropdown",
    options: [
      { text: "Válida", value: "valid" },
      { text: "No válida", value: "invalid" },
      { text: "No se proporcionó", value: "notProvided" },
    ],
  },
  {
    text: "Confirmación de respuesta del RENAPO",
    value: "confirmacion_respuesta_renapo",
    yup: Yup.mixed().oneOf(["valid", "invalid", "notProvided"]).required(),
    type: "dropdown",
    options: [
      { text: "Válida", value: "valid" },
      { text: "No válida", value: "invalid" },
      { text: "No se proporcionó", value: "notProvided" },
    ],
  },
  {
    text: "Ubica su cuenta de ahorro para el retiro en la AFORE",
    value: "ubicacion_cuenta_afore",
    // yup: Yup.string().required("La ubicación de la cuenta AFORE es requerida"),
    // type: "input",
    // options: "",
    yup: Yup.mixed()
      .oneOf([
        "AFORE Azteca",
        "Citibanamex AFORE",
        "AFORE Coppel",
        "PENSIONISSSTE",
        "AFORE Inbursa",
        "AFORE Invercap",
        "Principal AFORE",
        "Profuturo AFORE",
        "AFORE SURA",
        "AFORE XXI Banorte",
      ])
      .required(),
    type: "dropdown",
    options: [
      { text: "AFORE Azteca", value: "AFORE Azteca" },
      { text: "Citibanamex AFORE", value: "Citibanamex AFORE" },
      { text: "AFORE Coppel", value: "AFORE Coppel" },
      { text: "PENSIONISSSTE", value: "PENSIONISSSTE" },
      { text: "AFORE Inbursa", value: "AFORE Inbursa" },
      { text: "AFORE Invercap", value: "AFORE Invercap" },
      { text: "Principal AFORE", value: "Principal AFORE" },
      { text: "Profuturo AFORE", value: "Profuturo AFORE" },
      { text: "AFORE SURA", value: "AFORE SURA" },
      { text: "AFORE XXI Banorte", value: "AFORE XXI Banorte" },
    ],
  },
  {
    text: "CURP proporcionada coincide con CURP de SINDO",
    value: "curp_coincide_sindo",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
      { text: "No disponible en SINDO", value: "na" },
      { text: "No proporcionado", value: "np" },
    ],
  },
  {
    text: "NSS coincide con el NSS del SINDO",
    value: "nss_coincide_sindo",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
    ],
  },
  {
    text: "NSS coincide con el estado de cuenta de su AFORE",
    value: "nss_coincide_afore",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
      {
        text: "No proporcionó estado de cuenta de su AFORE",
        value: "np",
      },
    ],
  },
  {
    text: "RFC coincide con el estado de cuenta de su AFORE",
    value: "rfc_coincide_afore",
    type: "dropdown",
    options: [
      { text: "No corresponde", value: "no_corresponde" },
      {
        text: "Corresponde sin homoclave",
        value: "corresponde_sin_homoclave",
      },
      {
        text: "Corresponde con homoclave",
        value: "corresponde_con_homoclave",
      },
      {
        text: "No proporcionó estado de cuenta de su AFORE",
        value: "np",
      },
      {
        text: "No proporcionó RFC",
        value: "nprfc",
      },
    ],
  },
  {
    text: "Número de semanas en reporte digital",
    value: "semanas_reporte_digital",
    type: "number",
    options: "",
  },
  {
    text: "Número de semanas en reporte SINDO",
    value: "semanas_reporte_sindo",
    type: "number",
    options: "",
  },
  {
    text: "Fecha de cotización más antigua en régimen obligatorio",
    value: "fecha_cotizacion_antigua",
    type: "date",
    options: "",
  },
  {
    text: "Patrón de cotización más antiguo en régimen obligatorio",
    value: "patron_cotizacion_antigua",
    type: "text",
    options: "",
  },
  {
    text: "Fecha de cotización más reciente en régimen obligatorio",
    value: "fecha_cotizacion_reciente",
    type: "date",
    options: "",
  },
  {
    text: "Patrón de cotización más reciente en régimen obligatorio",
    value: "patron_cotizacion_reciente",
    type: "text",
    options: "",
  },
  {
    text: "Cuenta con las semanas mínimas para tramitar pensión.",
    value: "semanas_minimas_requeridas_pension",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
    ],
  },
  {
    text: "Número de semanas descontadas por préstamo en desempleo",
    value: "semanas_descontadas_prestamo_desempleo",
    type: "number",
    options: "",
  },
  {
    text: "Tiene conservación de derechos en SINDO",
    value: "conserva_derechos_sindo",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
    ],
  },
  {
    text: "Cuenta con vigencia de derechos para incorporarse a Modalidad 40",
    value: "vigencia_derechos_modalidad_40",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
    ],
  },
  {
    text: "Fecha límite de alta en modalidad 40",
    value: "fecha_limite_alta_modalidad_40",
    type: "date",
    options: "",
  },
  {
    text: "Salario base de cotización",
    value: "salario_base_cotizacion",
    type: "number",
    options: "",
    step: "0.10",
  },

  {
    text: "Salario promedio últimas 260 semanas",
    value: "salario_promedio_ultimas_260_semanas",
    type: "number",
    options: "",
  },
  {
    text: "Porcentaje del salario tope",
    value: "porcentaje_salario_tope",
    type: "number",
    options: "",
  },
];

export const afores = [
  { text: "AFORE Azteca", value: "AFORE Azteca" },
  { text: "Citibanamex AFORE", value: "Citibanamex AFORE" },
  { text: "AFORE Coppel", value: "AFORE Coppel" },
  { text: "PENSIONISSSTE", value: "PENSIONISSSTE" },
  { text: "AFORE Inbursa", value: "AFORE Inbursa" },
  { text: "AFORE Invercap", value: "AFORE Invercap" },
  { text: "Principal AFORE", value: "Principal AFORE" },
  { text: "Profuturo AFORE", value: "Profuturo AFORE" },
  { text: "AFORE SURA", value: "AFORE SURA" },
  { text: "AFORE XXI Banorte", value: "AFORE XXI Banorte" },
];

export const allowedFileTypes = [
  "application/pdf",
  "image/jpeg",
  "image/png",
  "image/gif"
];