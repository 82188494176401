import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Confirm, Divider, Form, Image } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { authenticateUser } from "../../../../store/auth";
import Logo from "../../../../assets/Logo-grande.png";

import "./Login.scss";

import { setError, setErrorCode } from "../../../../store/status";
import { NavLink, useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.status.isLoading);
  const navigate = useNavigate();

  const [ppPaymentConfirm, setPpPaymentConfirm] = useState(false);
  const [estudioPaymentConfirm, setEstudioPaymentConfirm] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("El correo electrónico es requerido"),
      password: Yup.string().required("La contraseña es requerida"),
    }),
    onSubmit: (formData) => {
      dispatch(authenticateUser(`${formData.email}`, `${formData.password}`));
    },
  });

  const handlePpPayment = () => {
    window.open(
      "https://pay.conekta.com/link/de9a4945ba69409a90d8a470cea272ea",
      "_blank"
    );
    setPpPaymentConfirm(false);
  };

  const handleEstudioPayment = () => {
    window.open(
      "https://pay.conekta.com/link/de9a4945ba69409a90d8a470cea272ea",
      "_blank"
    );
    setEstudioPaymentConfirm(false);
  };

  useEffect(() => {
    // navigate to /
    navigate("/");
    dispatch(setError(null));
    dispatch(setErrorCode(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form onSubmit={formik.handleSubmit} className="login">
        <Image src={Logo} centered className="login__logo" />
        <div className="login__subtitle">Iniciar sesión</div>
        <Form.Input
          type="email"
          placeholder="correo@electronico.com"
          name="email"
          label="Correo electrónico"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email}
          className="login__input"
        />
        <Form.Input
          type="password"
          placeholder="SuperSecretPassword"
          name="password"
          label="Contraseña"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={formik.touched.password && formik.errors.password}
          className="login__input"
        />
        <div>
          <Button
            type="button"
            onClick={formik.handleReset}
            id="secondary-button"
            disabled={isLoading}
          >
            Limpiar
          </Button>
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            id="primary-button"
            disabled={isLoading}
          >
            Enviar
          </Button>
        </div>
      </Form>

      <Confirm
        open={ppPaymentConfirm}
        onCancel={() => setPpPaymentConfirm(false)}
        onConfirm={() => handlePpPayment()}
        content="Realizar un pago de $5,000.00 MXN por un Proyectivo de Pensión"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
      />

      <Confirm
        open={estudioPaymentConfirm}
        onCancel={() => setEstudioPaymentConfirm(false)}
        onConfirm={() => handleEstudioPayment()}
        content="Realizar un pago de $2,000.00 MXN por un Estudio de Pensión"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
      />

      <Divider />
      <div className="login__register">
        <div className="login__register-text">¿Aún no tienes cuenta?</div>
        <NavLink to="/register">
          <Button type="button">Regístrarse</Button>
        </NavLink>
      </div>
      {/* <Divider /> */}
      {/* <div className="login__payments">
        <div id="buttons">
          <button
            onClick={() => setEstudioPaymentConfirm(true)}
            className="ui button"
          >
            Pagar un Estudio de Pensión
          </button>
          <button
            onClick={() => setPpPaymentConfirm(true)}
            className="ui button"
          >
            Pagar un Proyectivo de Pensión
          </button>
        </div>
      </div> */}
    </>
  );
};

export default Login;
